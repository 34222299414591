
.box-user .info{
    font-size: 13px;
  
}
.form-input img{
    border-radius: 7%;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    object-fit: cover;
}

.form-input  label{
    color: rgba(0, 0, 0, 0.6)
}

